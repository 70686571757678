@import './overrides';

h1 {
    font-size: 2rem;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gfD_vx3rCubqg.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2) format('woff2');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2) format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_epG3gfD_vx3rCubqg.woff2) format('woff2');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2) format('woff2');
}


html, body {
    overflow-x: hidden;
}

body {
    font-family: 'Montserrat', sans-serif;
}


.nav--bg {
    background-image: linear-gradient(45deg, #f6a318 0%, #eb634c 100%)!important;
}

.footer--bg {
    background-image: linear-gradient(45deg, #f6a318 0%, #eb634c 100%);
}

.info--bg {
    background-image: url("../img/info.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;


}

.info--bg__mob {
    background-image: url("../img/info.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    min-height: 65vh;

}

a {
    text-decoration: none!important;
}

p {
    margin: 0px!important;
    text-decoration: none!important;

}

img {
    min-width: 100%!important;
}

.rounded-2 {
    border-radius: 2rem!important;
}

.rounded-1 {
    border-radius: 1rem!important;
}

.rounded-05 {
    border-radius: 0.5rem!important;
}


.text-gradient {
    background: linear-gradient( #f6a318 0%, #eb634c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.vh-50{
    min-height: 50vh!important;
}

.rounded-left-top {
    border-top-left-radius: 0.5rem!important;
}

.rounded-right-top {
    border-top-right-radius: 0.5rem!important;
}

.rounded-bottom {
    border-bottom-left-radius: 0.5rem!important;
    border-bottom-right-radius: 0.5rem!important;
}



/*
font-family: 'Raleway', sans-serif;
font-family: 'Montserrat', sans-serif;
 */


.bg--gradient  {
    background-image: linear-gradient(45deg, #f6a318 0%, #eb634c 100%)!important;
    border-color: linear-gradient(45deg, #f6a318 0%, #eb634c 100%)!important;
}

.bg--gradient__contact  {
    background-color: #00DBDE;
    background-image: linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%);

}

.bg--gray  {
    background-image: linear-gradient(45deg, #f6a318 0%, #eb634c 100%)!important;
    border-color: linear-gradient(45deg, #f6a318 0%, #eb634c 100%)!important;
}

.form-control {
    border-color: #ffc107!important;


}

.custom-select {
    border-color: #ffc107!important;
    border-top: none;
    border-left: none;
    border-right: none;

}

.bg--action__bg__opacity {
    background-color: rgba(255, 255, 255, 0.8)!important;
}

.bg--event__gray {
    background-color: #f9f9f9 !important;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
}


.btn {
    border-radius: 2rem!important;
    white-space: normal!important;
}

.mh-50 {
    max-height: 50% !important;
}

.card {
    position: unset!important;
}

.visit {
    -webkit-filter: brightness(70%); /* Safari 6.0 - 9.0 */
    filter: brightness(70%);
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px!important;
}

.select--spa_stay {
    border: 2px solid #ffc107!important;
}



/* range input */

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ff9913;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffc107;
    cursor: pointer;
}


/* galerie */

.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 10px;
    grid-auto-rows: minmax(180px, auto);
    grid-auto-flow: dense;
    padding: 10px;
}

.grid-layout-hotel {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.grid-layout-hotel-not-big {
    height: min-content;
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-auto-flow: dense;
    padding: 10px;
}

.grid-layout-spa-hotel-not-big {
    height: min-content;
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-auto-flow: dense;
    padding: 10px;
}

.grid-layout-spa-hotel {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.grid-layout-spa {
    height: min-content;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    row-gap: 10px;
    column-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    grid-auto-flow: dense;
    padding: 10px;
}

.grid-item {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #929796;
    border-radius: 5px;
&:nth-child(odd) {
     background-color: #424242;
 }
}

.grid-item img {
    border-radius: 5px;
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.span-2 {
    grid-column-end: span 2;
    grid-row-end: span 2;
}

.span-3 {
    grid-column-end: span 3;
    grid-row-end: span 4;
}

.span-column-3 {
    grid-column-end: span 3;
}

/* /galerie */

.list-group-item {

   background-color: transparent!important;
   border: none!important;
    padding: 0.4rem 0.25rem!important;
}


.smap {
    touch-action: none;
    cursor: move;
}

.smap .overview-map {
    left: 0px;
    padding-right: 200px;
}

.guru {
    max-height: 300px!important;
}

.link-selected {
    font-weight: bold;
}

.link-original_color {
    color: inherit;
}

/* range input */

.irs--flat .irs-handle > i:first-child {
    background-color: #FFC107 !important;
}

.irs--flat .irs-bar {
    background-color: #FFC107 !important;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
    background-color: #FFC107 !important;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
    border-top-color: #FFC107 !important;
}

/* spa detail */
.spa-description-container {
    padding-top: 3px;
}

.reviews-link {
    color: black;
}

.reviews-link span {
    text-decoration: underline;
}

ul {
    padding-left: 30px;
}

li::marker {
    font-size: 2em;
}

table {
    border-color: #ECECEC;
    border-top: none;
    border-left: none;
    border-right: none;
}

th {
    text-align: center;
    background-color: #ECECEC;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px;
}

td {
    text-align: center;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px;
}

.hotel-link {
    color: black;
    text-decoration: underline;
}

.form-control-borderless {
    border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.card-img {
    transition: all 1s ease;
    -webkit-filter: brightness(70%); /* Safari 6.0 - 9.0 */
    filter: brightness(70%);
}

.card-div:hover > *:not(.card-img-overlay) {
    transition: all 1s ease;
    -webkit-filter: brightness(100%); /* Safari 6.0 - 9.0 */
    filter: brightness(100%);
}

.gradient-elements a {
    color: #FF9913;
}

.child-link-white-colour a {
    color: white;
}